import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Splash from './pages/Splash';
import ImageLoader from './pages/ImageLoader';
import ColorPicker from './pages/ColorPicker';
const SignIn = lazy(() => import('./pages/SignIn'));
const SignUp = lazy(() => import('./pages/SignUp'));
const Statistics = lazy(() => import('./pages/statistics/Statistics'));

import './App.css';
import Header from './components/modal/Header';
import FallbackPage from './pages/FallbackPage';
import OnBoarding from './pages/onboarding/OnBoarding';
import TermsOfServiceAgreement from './pages/TermsOfServiceAgreement';
import PrivacyPolicy from './pages/PrivacyPolicy';
import MarketingConsentForm from './pages/MarketingConsentForm';
import CreateAccount from './pages/CreateAccount';
import ForgotPwd from './pages/ForgotPwd';
import ResetPassword from './pages/ResetPassword';

function App() {
	return (
		<div className="app-bg w-screen h-screen flex-row justify-center">
			{/* <Header></Header> */}
			<div className="router-display h-screen flex flex-col justify-center items-center">
				<Router>
					<Header></Header>
					<Suspense fallback={<FallbackPage></FallbackPage>}>
						<Routes>
							<Route index path="/" element={<Splash></Splash>} />
							<Route
								path="/upload"
								element={<ImageLoader></ImageLoader>}
							/>
							<Route
								path="/pick"
								element={<ColorPicker></ColorPicker>}
							/>
							<Route path="/signin" element={<SignIn></SignIn>} />
							<Route path="/signup" element={<SignUp></SignUp>} />
							<Route
								path="/statistics"
								element={<Statistics></Statistics>}
							/>
							<Route
								path="/onboarding"
								element={<OnBoarding></OnBoarding>}
							/>
							<Route
								path="/service/agreement"
								element={
									<TermsOfServiceAgreement></TermsOfServiceAgreement>
								}
							/>
							<Route
								path="/service/marketing"
								element={
									<MarketingConsentForm></MarketingConsentForm>
								}
							/>
							<Route
								path="/service/privacyPolicy"
								element={<PrivacyPolicy></PrivacyPolicy>}
							/>

							<Route
								path="/createAccount"
								element={<CreateAccount></CreateAccount>}
							/>
							<Route
								path="/forgot-password"
								element={<ForgotPwd></ForgotPwd>}
							/>
							<Route
								path="/reset-password/:hash"
								element={<ResetPassword></ResetPassword>}
							/>
						</Routes>
					</Suspense>
				</Router>
			</div>
		</div>
	);
}

export default App;
